import * as React from 'react';
import { useEffect } from 'react';
import GeoService from './Helper/GeoService';
import ApolloClientBuilder from './GraphQL/Waffle/ApolloClientBuilder';
import { ApolloClient, ApolloProvider } from '@apollo/client';

interface Props {
  children: React.ReactNode;
}

function setupForceGeoLocationUpdate(apolloClient: ApolloClient<object>) {
  // note: this seems to blow up
  // let clearApolloResetSubscription;

  apolloClient.onResetStore(async () => {
    await GeoService.forceUpdate(apolloClient);
  });

  return function cleanup() {
    // clearApolloResetSubscription();
    GeoService.stopService();
  };
}

export default function GraphQLProvider({ children }: Props): JSX.Element {
  const apolloClient = ApolloClientBuilder();

  useEffect(() => setupForceGeoLocationUpdate(apolloClient));

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
