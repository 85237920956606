/**
 * Build Apollo Client for application.  Includes definition of client-side types.
 */

import {
  ApolloLink,
  ApolloClient,
  gql,
  InMemoryCache,
  createHttpLink,
  makeVar,
} from '@apollo/client';
import GetGraphqlRoot from '../../Helper/GetGraphqlRoot';
import { Logger } from '../../Helper/Logger';
import { GetTokenFromAsync } from '../../Helper/UserHelpers';
import introspectionQueryResultData from './fragmentTypes.json';
import { GET_LOCAL_POSITION, GET_TRAFFIC, GET_WEATHER } from './Queries';
import { onError } from '@apollo/client/link/error';

// https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
// Was removed. Use possible types now ^
// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });

const cache = new InMemoryCache();

const typeDefs = gql`
  extend type RootQuery {
    userId: String
    user: LocalUser
    totalPrice: Float
    calendar: [Event]
    position: Coords
    steps: Steps
    stairs: Stairs
    traffic: Traffic
    weather: LocalWeather
  }

  type Event {
    id: String
    title: String
    startDate: String
    endDate: String
    allDay: Boolean
  }

  type Steps {
    yesterday: Float
    average: Float
    today: Float
  }

  type Stairs {
    yesterday: Float
    average: Float
    today: Float
  }

  type Traffic {
    num_incidents: Float
    nearest: String
  }

  type LocalUser {
    id: String
    firstName: String
    lastName: String
    email: String
    userState: String
    dateOfBirth: String
    address: String
    address2: String
    city: String
    state: String
    zip: String
    userType: String
  }

  type LocalWeather {
    temperatureHigh: Float
    temperatureLow: Float
    precipProbability: Float
    uvIndex: Float
    ozone: Float
  }

  type Coords {
    latitude: Float
    longitude: Float
  }
`;

type BackendTrackingInfo = {
  clickId?: string;
  utmSource?: string;
  utmCampaign?: string;
};

export const cachedTokenReactiveVar = makeVar<string>('');
export const cachedTrackingInfo = makeVar<BackendTrackingInfo>({
  clickId: null,
  utmCampaign: null,
  utmSource: null,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    const token = cachedTokenReactiveVar();

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  return forward(operation);
});

const resetTokenLink = onError(({ networkError }) => {
  if (networkError && networkError.name === 'ServerError') {
    // remove cached token on 401 from the server
    cachedTokenReactiveVar(null);
  }
});

const httpLink = createHttpLink({ uri: GetGraphqlRoot().api });
export default function ApolloClientBuilder(): ApolloClient<object> {
  Logger('starting fn');

  // Is it possible to selectively cache things?

  const client = new ApolloClient<object>({
    cache,
    uri: GetGraphqlRoot().api,
    link: ApolloLink.from([authLink, resetTokenLink, httpLink]),
    typeDefs,
  });

  // Base data
  const data = {};

  const onResetData = () => {
    Logger(`ApolloClientBuilder RESET!`);
    // Default values for coverage:
    //             id: y.name,
    //             subtitle: y.subtitle || null,
    //             policyDescription: y.policyDescription || y.name, // We use name as fallback, but shouldn't do this in production
    //             unitDescription: y.unitDescription || '',
    //             value: y.defaultValue || 0, // Math.random(),  // temp for now!
    //             unit: y.unit || '#',
    //             step: y.step || 1,
    //             minimum: y.minimum || 0,
    //             maximum: y.maximum || 100,
    //             selected: y.selected || false,

    // Alert.alert(JSON.stringify(products));

    client.writeQuery({
      query: GET_LOCAL_POSITION,
      data: {
        userId: '',
        calendar: [],
        position: {
          __typename: 'Coords',
          latitude: 0,
          longitude: 0,
        },
      },
    });

    client.writeQuery({
      query: GET_TRAFFIC,
      data: {
        traffic: {
          __typename: 'Traffic',
          num_incidents: 0,
          nearest: 'nothing',
        },
      },
    });

    client.writeQuery({
      query: GET_WEATHER,
      data: {
        weatherToday: {
          __typename: 'LocalWeather',
          temperatureHigh: 0,
          temperatureLow: 0,
          precipProbability: 0,
          uvIndex: 0,
          windSpeed: 0,
          ozone: 0,
        },
      },
    });
  };

  onResetData();

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => void' is not assignable to... Remove this comment to see the full error message
  client.onResetStore(onResetData);

  return client;
}
