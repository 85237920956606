import { Dimensions, Platform, ScaledSize, StyleSheet } from 'react-native';
// import { Header } from 'react-navigation-stack'; https://reactnavigation.org/docs/stack-navigator/#headertransparent
import { Logger } from '../Helper/Logger';
import { hasBottomSwitcher } from '../Helper/FeatureFlags';
import { isFullWeb } from '../Helper/DeviceHelper';
import { isMobileSafari } from '../Helper/DeviceHelper.web';

// todo : come up with batter way of exporting this!

export const WaffleYellow = '#FFD627';
export const WaffleDarkBlue = '#051A3A';
export const WaffleDarkBlueDesaturated = '#051A3A7F';
export const WaffleOrange = '#FA8E46'; // was '#ffab50'
export const WaffleOrangeDesaturated = '#FA8E467F';
export const WaffleRed = '#FF6666';
export const WaffleMediumRed = '#E94D4E';
export const WaffleGreen = '#B9DD4A';
export const WaffleMediumGreen = '#85DF83';
export const WaffleLightBlue = '#5AC3ED';
export const WaffleDarkGrey = '#D8D8D8';
export const WaffleExtraDark = '#051A3A';
export const WaffleMediumGrey = '#DADADA';
export const WaffleGrey = '#D4D4D4';
export const WaffleLightGrey = '#F4F4F4';
export const White = '#FFFFFF';
export const Black = '#000000';
export const WaffleFadedBlack = 'rgba(0,0,0,0.5)';
export const WaffleBackground = '#fff8f3';

export const DarkText = WaffleDarkBlue;
export const LightText = White;

export const FooterHeight = 83;

// todo : 160?
// this can be useHeaderHeight in React Navigation v5
export const HeaderNavHeight = isFullWeb() ? 0 : 80; // ??

export const LargerHeaderNavHeight = 90;

export const ShortHeaderNavHeight = isFullWeb() ? 0 : 80; // (Header as any).HEIGHT;

export const IsFullSize = (layout: ScaledSize) => layout.width > 960;

export const IsMidSize = (layout: ScaledSize) => layout.width <= 960;

export const MenuWidth = (layout: ScaledSize) => (IsMidSize(layout) ? 80 : 240);

export const ScreenHeight = () => Dimensions.get('window').height;

export const WindowWidth = () => Dimensions.get('window').width;

export const DefaultGridGap = isFullWeb() && { columnGap: 20, rowGap: 20 };

// TODO: figure out a way to import images
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const LogoLargeImage = require('../../assets/images/Waffle_Logo_RGB_DarkBlue.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const AppStoreDownloadImage = require('../../assets/images/appStoreDownloadImage.png');
// export const LogoLargeImage = require('../../assets/images/Logo_large.jpg');

const NotchCorrection = Platform.OS === 'web' || hasBottomSwitcher() ? 0 : 24; // We already factor in notch, so for notchless devices, we have to add back a value

Logger(
  `STYLE header height: ${HeaderNavHeight} nav stack header height: notch: ${hasBottomSwitcher()} screen: ${JSON.stringify(
    Dimensions.get('window')
  )}`
);

const styles = StyleSheet.create({
  // Colors
  fullHeight: {
    // https://stackoverflow.com/questions/47976358/how-to-make-react-native-web-full-height
    //    100vh for web allows it to resize when browser resizes!
    height: isFullWeb()
      ? '100vh'
      : isMobileSafari()
      ? '100%'
      : Dimensions.get('window').height,
  },
  fullHeightMinusHeader: {
    height: isFullWeb()
      ? '100vh'
      : Dimensions.get('window').height - HeaderNavHeight,
  },
  fullHeightMinusShortHeader: {
    height: isFullWeb()
      ? '100vh'
      : Dimensions.get('window').height -
        ShortHeaderNavHeight +
        NotchCorrection,
  },
  fullHeightMinusHeaderFooter: {
    height: isFullWeb()
      ? '100vh'
      : Dimensions.get('window').height - HeaderNavHeight - FooterHeight,
  },
  fullHeightMinusShortHeaderFooter: {
    height: isFullWeb()
      ? '100vh'
      : Dimensions.get('window').height -
        ShortHeaderNavHeight -
        FooterHeight +
        NotchCorrection,
  },
  height65Pct: {
    height: isFullWeb() ? '65vh' : Dimensions.get('window').height * 0.65,
  },
  fullWidth: {
    width: '100%',
  },
  fullMaxWidth: {
    maxWidth: '100%',
  },
  redBorder: {
    borderWidth: 3,
    borderColor: 'red',
  },
  blueBorder: {
    borderWidth: 3,
    borderColor: 'blue',
  },
  greenBorder: {
    borderWidth: 4,
    borderColor: 'green',
  },
  invisibleBorder: {
    borderWidth: 0.1,
    borderColor: 'transparent',
  },

  whiteBackground: {
    backgroundColor: '#FFFFFF',
  },

  // new colors
  // waffleYellow: {
  //   color: WaffleYellow,
  // },
  // waffleDarkBlue: {
  //   color: WaffleDarkBlue,
  // },
  waffleOrange: {
    color: WaffleOrange,
  },
  // waffleGreen: {
  //   color: WaffleGreen,
  // },
  // waffleLightBlue: {
  //   color: WaffleLightBlue,
  // },
  // waffleDarkGrey: {
  //   color: WaffleDarkGrey,
  // },

  // old colors
  // waffleOrange: {
  //   color: '#ffab50',
  // },
  // waffleBlue: {
  //   color: '#4297e7',
  // },
  waffleBlack: {
    color: '#4a4a4a',
  },
  //
  // waffleBlue1: {
  //   color: '#89c6ff',
  // },
  // waffleBlue2: {
  //   color: '#1f3c88',
  // },
  // waffleBlue3: {
  //   color: '#070d59',
  // },
  // waffleRed: {
  //   color: '#FF0C01',
  // },

  // Text styles.   Light = 300, Normal = 400, Medium = 500, Bold = 700.
  // See: https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight

  // layout styles
  verticalPackedCenter: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  verticalCenter: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // Boxes
  verticalBox: {
    // this is bogon!
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
