import { ImageSourcePropType, Platform } from 'react-native';

interface ProductImagesType {
  selectedImg: ImageSourcePropType;
  unselectedImg: ImageSourcePropType;
  detailImg: ImageSourcePropType;
  webImg?: ImageSourcePropType;
}

export const ProductCar = 'Car';
export const ProductCyber = 'Cyber';
export const ProductHome = 'Home';
export const ProductLife = 'Life';
export const ProductPet = 'Pet';
export const ProductRenters = 'Renters';
export const ProductTravel = 'Travel';
export type ProductName =
  | 'Car'
  | 'Cyber'
  | 'Home'
  | 'Life'
  | 'Pet'
  | 'Renters'
  | 'Travel';
const Products: { [key: string]: ProductImagesType } = {
  Phone: {
    selectedImg: require('../../assets/images/products/item-clicked/phone.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/phone-new.png'),
    detailImg: require('../../assets/images/products/details/details-phone-new.png'),
    webImg: require('../../assets/images/products/product-images/phone.webp'),
  },
  Computer: {
    selectedImg: require('../../assets/images/products/item-clicked/computer.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/computer-new.png'),
    detailImg: require('../../assets/images/products/details/details-computer-new.png'),
  },
  [ProductCyber]: {
    selectedImg: require('../../assets/images/products/item-clicked/computer.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/computer-new.png'),
    detailImg: require('../../assets/images/products/details/details-computer-new.png'),
    webImg: require('../../assets/images/products/product-images/cyber.webp'),
  },
  [ProductHome]: {
    selectedImg: require('../../assets/images/products/item-clicked/home.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/home-new.png'),
    detailImg: require('../../assets/images/products/details/details-home-new.png'),
    webImg: require('../../assets/images/products/product-images/home.webp'),
  },
  [ProductTravel]: {
    selectedImg: require('../../assets/images/products/item-clicked/travel.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/travel-new.png'),
    detailImg: require('../../assets/images/products/details/details-travel-new.png'),
    webImg: require('../../assets/images/products/product-images/travel.webp'),
  },
  Tablet: {
    selectedImg: require('../../assets/images/products/item-clicked/tablet.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/tablet-new.png'),
    detailImg: require('../../assets/images/products/details/details-tablet-new.png'),
  },
  [ProductCar]: {
    selectedImg: require('../../assets/images/products/item-clicked/car.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/car-new.png'),
    detailImg: require('../../assets/images/products/details/details-car-new.png'),
    webImg: require('../../assets/images/products/product-images/car.webp'),
  },
  Bike: {
    selectedImg: require('../../assets/images/products/item-clicked/bike.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/bike-new.png'),
    detailImg: require('../../assets/images/products/details/details-bike-new.png'),
  },
  [ProductRenters]: {
    selectedImg: require('../../assets/images/products/item-clicked/renters.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/renters-new.png'),
    detailImg: require('../../assets/images/products/details/details-renters-new.png'),
    webImg: require('../../assets/images/products/product-images/renter.webp'),
  },
  [ProductPet]: {
    selectedImg: require('../../assets/images/products/item-clicked/pet.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/pet-new.png'),
    detailImg: require('../../assets/images/products/details/details-pets-new.png'),
    webImg: require('../../assets/images/products/product-images/pet.webp'),
  },
  Friend: {
    selectedImg: require('../../assets/images/products/item-clicked/friend.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/friend-new.png'),
    detailImg: require('../../assets/images/products/details/details-friend-new.png'),
  },
  Family: {
    selectedImg: require('../../assets/images/products/item-clicked/family.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/family-new.png'),
    detailImg: require('../../assets/images/products/details/details-family-new.png'),
  },
  [ProductLife]: {
    selectedImg: require('../../assets/images/products/item-clicked/life.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/life-new.png'),
    detailImg: require('../../assets/images/products/details/details-life-new.png'),
    webImg: require('../../assets/images/products/product-images/life.webp'),
  },
  Health: {
    selectedImg: require('../../assets/images/products/item-clicked/health.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/health-new.png'),
    detailImg: require('../../assets/images/products/details/details-health-new.png'),
  },
  Tuition: {
    selectedImg: require('../../assets/images/products/item-clicked/tuition.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/tuition-new.png'),
    detailImg: require('../../assets/images/products/details/details-tuition-new.png'),
  },
  Watch: {
    selectedImg: require('../../assets/images/products/item-clicked/watch.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/watch.png'),
    detailImg: require('../../assets/images/products/details/details-watch-new.png'),
  },
  Jewelry: {
    selectedImg: require('../../assets/images/products/item-clicked/jewelry.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/jewelry.png'),
    detailImg: require('../../assets/images/products/details/details-jewelry-new.png'),
  },
  Art: {
    selectedImg: require('../../assets/images/products/item-clicked/art.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/art.png'),
    detailImg: require('../../assets/images/products/details/details-art-new.png'),
  },
  Motorcycle: {
    selectedImg: require('../../assets/images/products/item-clicked/motorcycle.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/motorcycle.png'),
    detailImg: require('../../assets/images/products/details/details-motorcycle-new.png'),
  },
  Valuables: {
    selectedImg: require('../../assets/images/products/item-clicked/tablet.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/tablet-new.png'),
    detailImg: require('../../assets/images/products/details/details-tablet-new.png'),
  },
  Disability: {
    selectedImg: require('../../assets/images/products/item-clicked/phone.png'),
    unselectedImg: require('../../assets/images/products/item-unclicked/phone-new.png'),
    detailImg: require('../../assets/images/products/details/details-phone-new.png'),
    webImg: require('../../assets/images/products/product-images/phone.webp'),
  },
};

// Checks if a list of products contains one that produces at exit from
// the website or app to the partner.  (For example, we only quote the
// produce, and relay the customer to the partner to finish the application
// and collect payment.)
export const ContainsExternalExitProduct = (products: string[]) =>
  products?.length == 1 &&
  (products[0] === ProductHome ||
    (Platform.OS === 'web' && products[0] === ProductPet));

export default Products;
