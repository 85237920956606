import { gql } from '@apollo/client';

export const USER_POLICIES = gql`
  query GetFinalizedUserPolicies($input: ViewPoliciesInput) {
    finalizedUserPolicies(input: $input) {
      id
      productId
      product {
        id
        name
        suggestedCoverageHeader
        suggestedCoverageFooter
        suggestedCoverageExampleRestrictions {
          title
          description
        }
        suggestedCoveragePlanHighlights {
          title
          description
        }
        policyDescription
        templates
      }
      price
      status
      items {
        description
        price
      }
      answerInputs {
        __typename
        ... on PolicyPetsAnswer {
          Pets {
            Name
            Species
            BreedCode
            BreedName
            Gender
            Age
            Spayed
          }
        }
        ... on PolicyRentersAnswer {
          IsRequired
          MoreThan100Units
          LessThan20Years
          StartDate
          AdditionalInsured {
            FirstName
            LastName
          }
          AdditionalInterest {
            BusinessName
            Email
            Address1
            Address2
            City
            State
            Zip
          }
        }
        ... on PolicyLifeAnswer {
          Gender
          Height
          Weight
          IsSmoker
          HealthCategory
        }
        ... on PolicyTripAnswer {
          TripDate
          ExpireDate
          TripPrice
          DestinationCountry
          DepositDate
          CreditOrVoucher
          CancelForAnyReasonEligible
          CoveredTraveler {
            Address {
              AddressLine
              CityName
              CountryName {
                Code
                Value
              }
              StateProv {
                StateCode
              }
            }
            CoveredPerson {
              BirthDate
              GivenName
              MiddleName
              Surname
            }
            Email
            RPH
            Telephone {
              PhoneNumber
            }
          }
        }
      }
      addOns {
        id
        name
        explanation
        selected
        price
      }
      externalQuoteId
      externalPolicyId
      externalPolicyNumber
      externalPolicy {
        ExternalPolicyId
        PolicyDocumentURL
        DescriptionOfCoverageURL
        ContinuationURL
        COIDocumentId
        ExternalApplicationId
        ExternalEmail
        ExternalUserId
        OfferId
        RequestedQuoteTerm
      }
      policyType
      coverage {
        id
        productCoverageId
        coverageLimit
        coverage {
          id
          name
          subtitle
          description
          policyDescription
          unitDescription
          coverageUnit
          minimumCoverage
          maximumCoverage
          unitPer
          step
          selectionType
          choices
          initialValue
          explanation {
            title
            url
            text
          }
        }
      }
    }
  }
`;

/* User policy operations */
// See PolicyHelper for different status codes
export const DRAFT_POLICIES = gql`
  query GetDraftUserPolicies {
    draftUserPolicies {
      id
      productId
      product {
        id
        name
        suggestedCoverageHeader
        suggestedCoverageFooter
        suggestedCoverageExampleRestrictions {
          title
          description
        }
        suggestedCoveragePlanHighlights {
          title
          description
        }
        policyDescription
      }
      price
      status
      items {
        description
        price
      }
      answerInputs {
        __typename
        ... on PolicyPetsAnswer {
          Pets {
            Name
            Species
            BreedCode
            BreedName
            Gender
            Age
            Spayed
          }
        }
        ... on PolicyRentersAnswer {
          IsRequired
          MoreThan100Units
          LessThan20Years
          StartDate
          AdditionalInsured {
            FirstName
            LastName
          }
          AdditionalInterest {
            BusinessName
            Email
            Address1
            Address2
            City
            State
            Zip
          }
        }
        ... on PolicyLifeAnswer {
          Gender
          Height
          Weight
          IsSmoker
          HealthCategory
        }
        ... on PolicyTripAnswer {
          TripDate
          ExpireDate
          TripPrice
          DestinationCountry
          DepositDate
          CreditOrVoucher
          CancelForAnyReasonEligible
          CoveredTraveler {
            Address {
              AddressLine
              CityName
              CountryName {
                Code
                Value
              }
              StateProv {
                StateCode
              }
            }
            CoveredPerson {
              BirthDate
              GivenName
              MiddleName
              Surname
            }
            Email
            RPH
            Telephone {
              PhoneNumber
            }
          }
        }
      }
      addOns {
        id
        name
        explanation
        selected
        price
      }
      externalQuoteId
      externalPolicy {
        ExternalQuoteId
        ContinuationURL
        ExternalPolicyId
        PolicyDocumentURL
        DescriptionOfCoverageURL
        COIDocumentId
        ExternalApplicationId
        ExternalEmail
        ExternalUserId
        OfferId
        RequestedQuoteTerm
      }
      policyType
      coverage {
        id
        productCoverageId
        coverageLimit
        coverage {
          id
          name
          subtitle
          description
          policyDescription
          unitDescription
          coverageUnit
          minimumCoverage
          maximumCoverage
          unitPer
          step
          selectionType
          choices
          initialValue
          explanation {
            title
            url
            text
          }
        }
      }
    }
  }
`;

// New pricing-related methods
// todo : define body as a fragment?

export const CREATE_DRAFT_POLICIES = gql`
  mutation CreateDraftPolicies($input: [String!]) {
    createDraftPolicies(input: $input) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
    }
  }
`;

export const CREATE_OR_RESUME_DRAFT_POLICIES = gql`
  mutation CreateOrResumeDraftPolicies($input: [String!]) {
    createOrResumeDraftPolicies(input: $input) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
    }
  }
`;

export const UPDATE_POLICY_TYPE_AND_REPRICE = gql`
  mutation UpdatePolicyTypeAndReprice($input: UserPolicyInput) {
    updatePolicyTypeAndReprice(input: $input) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
      update_status {
        message
      }
    }
  }
`;

export const UPDATE_POLICY_ANSWERS_AND_REPRICE = gql`
  mutation UpdatePolicyAnswerAndReprice($input: UserPolicyInput) {
    updatePolicyAnswersAndReprice(input: $input) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
      update_status {
        message
      }
    }
  }
`;

export const REPRICE_DRAFT_POLICIES = gql`
  mutation RepriceDraftPolicies {
    repriceDraftPolicies {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
      update_status {
        message
      }
    }
  }
`;

export const UPDATE_COVERAGE_AND_REPRICE = gql`
  mutation UpdateCoverageAndReprice($input: UserPolicyCoverageInput) {
    updateCoverageAndReprice(input: $input) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
      update_status {
        message
      }
    }
  }
`;

export const TOGGLE_ADD_ON_AND_REPRICE = gql`
  mutation ToggleAddOn($input: UserPolicyAddOnInput) {
    togglePolicyAddonAndReprice(input: $input) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
      update_status {
        message
      }
    }
  }
`;

export const RESET_DRAFT_POLICY = gql`
  mutation ResetDraftPolicy($policy_id: String!) {
    resetDraftPolicy(policy_id: $policy_id) {
      id
      product_id
      price
      items
      add_ons
      answer_inputs
      coverage {
        __typename
        id
        coverage_limit
      }
      update_status {
        message
      }
    }
  }
`;

/////

export const GET_ALL_MESSAGES = gql`
  query GetAllMessages($last_received_id: Int, $last_received_ts: String) {
    listCurrentMessages(
      last_received_id: $last_received_id
      last_received_ts: $last_received_ts
    ) {
      id
      text
      username
      choices
      attachmentUrl
      party
      action
    }
  }
`;

export const INSERT_MESSAGE = gql`
  mutation InsertMessage($message: MessageInput) {
    insertMessage(input: $message) {
      affectedRows
    }
  }
`;

/////

export const SUGGEST_LOCATION = gql`
  query SuggestLocation(
    $query: String!
    $latitude: Float
    $longitude: Float
    $state: String
  ) {
    suggestLocation(
      query: $query
      latitude: $latitude
      longitude: $longitude
      state: $state
    ) {
      suggestions {
        matchLevel
        address {
          houseNumber
          street
          city
          state
          postalCode
          country
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($build_version: Int, $platform: String) {
    products(build_version: $build_version, platform: $platform) {
      id
      name
      description
      enabled
      policyDescription
      availableStates {
        only
        except
      }
      eligibility {
        isEligible
        policyCount
        message
        warning
      }
      suggestedCoverageHeader
      suggestedCoverageFooter
      disclosures
      multiple
      reviewPolicyDisclosure
      coverage {
        id
        name
        selected
        subtitle
        description
        policyDescription
        coverageUnit
        minimumCoverage
        maximumCoverage
        unitDescription
        unitPer
        step
        selectionType
        choices
        initialValue
        explanation {
          title
          text
          url
        }
      }
    }
  }
`;

export const GET_BREEDS = gql`
  query GetBreeds($species: String!) {
    petBreeds(species: $species) {
      name
      code
      type
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    listCountries {
      code
      name
    }
  }
`;

export const GET_STATES = gql`
  query GetStates {
    listStates {
      code
      name
    }
  }
`;

export const GET_LOCAL_USER_ID = gql`
  query GetLocalUserId {
    userId @client
  }
`;

export const GET_LOCAL_POSITION = gql`
  query LocalPosition {
    position @client {
      latitude
      longitude
    }
  }
`;

export const GET_LOCAL_USER = gql`
  query LocalUser {
    user @client {
      id
      firstName
      lastName
      email
      userState
      address
      address2
      city
      state
      zip
      phone
      dateOfBirth
      userType
    }
  }
`;

export const CREATE_USER_DETAIL = gql`
  mutation CreateUserDetail(
    $id: String!
    $firstName: String!
    $lastName: String!
    $dateOfBirth: String!
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $phone: String!
    $referralCode: String!
  ) {
    createUserDetail(
      id: $id
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      phone: $phone
      referralCode: $referralCode
    ) {
      user {
        id
        email
        firstName
        lastName
        dateOfBirth
        address
        address2
        city
        state
        zip
        phone
        userState
        referralCode
      }
    }
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserDetails($input: UpdateUserInput!) {
    updateUserDetail(input: $input) {
      id
      firstName
      lastName
      dateOfBirth
      address
      address2
      city
      state
      zip
      phone
      referralCode
    }
  }
`;

export const UPDATE_GUEST_USER_DETAILS = gql`
  mutation UpdateGuestUserDetails($input: UpdateUserInput!) {
    updateGuestUserDetail(input: $input) {
      id
      firstName
      lastName
      dateOfBirth
      address
      address2
      city
      state
      zip
      phone
      referralCode
      userState
    }
  }
`;

export const VALIDATE_USER_ADDRESS = gql`
  mutation ValidateUserAddress($input: AddressValidationInput!) {
    validateAddress(input: $input) {
      isValid
      message
      suggestions {
        address
        address2
        city
        state
        zip
      }
    }
  }
`;

export const CREATE_GUEST_USER = gql`
  mutation CreateGuestUser($input: GuestUserInput!) {
    createGuestUser(input: $input) {
      token
      message
      user {
        id
        email
        firstName
        lastName
        dateOfBirth
        address
        address2
        city
        state
        zip
        phone
        userState
        referralCode
        userType
      }
    }
  }
`;

export const REFRESH_GUEST_USER = gql`
  mutation RefreshGuestUser($input: GuestRefreshInput!) {
    refreshGuestUser(input: $input) {
      token
      message
    }
  }
`;

// fetches currently authenticated user
export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      firstName
      lastName
      email
      userState
      address
      address2
      city
      state
      zip
      phone
      dateOfBirth
      userType
    }
  }
`;

export const GET_USER_MESSAGES = gql`
  query GetUserMessages {
    userMessages {
      _id
      partyId
      user {
        _id
        name
      }
      text
      choices
      action
      value
      createdAt
      validators
    }
  }
`;

export const PROCESS_USER_RESPONSE = gql`
  mutation ProcessResponse($userId: String!, $answer: String!, $value: String) {
    processResponse(userId: $userId, answer: $answer, value: $value) {
      userId
      question
      status
    }
  }
`;

export const FINALIZE_DRAFT_USER_POLICIES = gql`
  mutation FinalizeUserPolicies2($paymentMethodId: String) {
    finalizeDraftUserPolicies(paymentMethodId: $paymentMethodId) {
      responses {
        isSuccess
        policyId
        productId
        status
        message
        externalPolicyId
        externalPolicyNumber
      }
    }
  }
`;

export const POPULATE_USER_POLICY_ANSWERS = gql`
  mutation PopulateUserPolicyAnswers {
    populatePolicyAnswerInputs
  }
`;

export const CREATE_REFERRAL = gql`
  mutation CreateReferral($email: String!) {
    createReferral(email: $email)
  }
`;

export const UPDATE_USER_STATE = gql`
  mutation UpdateUserState($state: String!) {
    updateUserState(userState: $state) {
      userState
    }
  }
`;

export const GET_PET_POLICY_QUOTE_ID = gql`
  query PetPolicyQuoteId {
    getPetPolicy {
      externalQuoteId
    }
  }
`;

export const UPDATE_PET_POLICY_EXTERNAL_POLICY = gql`
  mutation UpdatePetPolicy($input: PetPolicyExternalInput) {
    updatePetPolicyInformation(input: $input)
  }
`;

export const GET_WEATHER = gql`
  query GetWeather($latitude: Float!, $longitude: Float!) {
    weatherToday(latitude: $latitude, longitude: $longitude) {
      temperatureHigh
      temperatureLow
      precipProbability
      uvIndex
      ozone
      windSpeed
    }
  }
`;

export const GET_TRAFFIC = gql`
  query GetTraffic {
    traffic @client {
      num_incidents
      nearest
    }
  }
`;

export const CREATE_SETUP_INTENT = gql`
  mutation CreateSetupIntent {
    createSetupIntent {
      clientSecret
    }
  }
`;

export const GET_CUSTOMER_PAYMENT_METHODS = gql`
  query ListCustomerPaymentMethods {
    listCustomerPaymentMethods {
      id
      card {
        brand
        expMonth
        expYear
        last4
      }
    }
  }
`;

export const CREATE_RENTERS_ADDITIONAL_INTEREST = gql`
  mutation CreateRentersAdditionalInterest(
    $input: RentersAdditionalInterestInput!
  ) {
    createRentersAdditionalInterest(input: $input)
  }
`;
